import type { ImmutSet } from '@eturi/util'
import type { Draft } from '@reduxjs/toolkit'

export type WritableDraft<T> = { -readonly [K in keyof T]: Draft<T[K]> }

export type FixedDraft<S, K extends keyof S, D extends WritableDraft<S>> = Omit<D, K> &
	Writable<Pick<S, K>>

/**
 * Identity type helper that will cast WritableDraft with ImmutSet to a proper
 * writable type that allows assigning to ImmutSet fields
 *
 * I tried to make this work via type inference, but it simply refuses. This is
 * likely due to the recursive nature of drafts, but I don't know for sure.
 *
 * First generic is required.
 */
export const castImmutSet = <S, D extends WritableDraft<S> = WritableDraft<S>>(
	draft: D,
): FixedDraft<S, KeysByType<S, ImmutSet<any>>, D> => draft as any
